import { render, staticRenderFns } from "./AdminPanelCooldowns--draft.vue?vue&type=template&id=8f8d9c34&scoped=true&"
import script from "./AdminPanelCooldowns--draft.vue?vue&type=script&lang=js&"
export * from "./AdminPanelCooldowns--draft.vue?vue&type=script&lang=js&"
import style0 from "./AdminPanelCooldowns--draft.vue?vue&type=style&index=0&id=8f8d9c34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f8d9c34",
  null
  
)

export default component.exports