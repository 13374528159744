<script>
  import { mapGetters } from "vuex";
  import AdminPanelGridButton from "../../../AdminUI/AdminPanelGridButton.vue";
  import AdminPanelGridUnderlay from "../../../AdminUI/AdminPanelGridUnderlay.vue";

  export default {
    components: {
      AdminPanelGridButton,
      AdminPanelGridUnderlay,
    },
    computed: {
      ...mapGetters({
        getAllCooldowns: "cooldowns/getAllCooldowns",
      }),
      activeCooldowns() {
        return this.getAllCooldowns.filter((cooldown) => cooldown.expiresAt);
      },
    },
    methods: {
      handleClick(task = {}) {
        this.$store.dispatch("tasks/runTasksRemotely", [{ uuid: task.uuid }]);
      },
    },
  };
</script>

<template>
  <div class="admin-panel-links-parent">
    <div class="has-scrollbars">
      <div class="scrollbars-inner">
        <AdminPanelGridUnderlay />

        <div class="grid-btns">
          <AdminPanelGridButton
            v-for="(cooldown, index) in getAllCooldowns"
            :icon="cooldown.icon"
            :isActive="false"
            :key="index"
            :name="cooldown.group + ' ' + cooldown.data.value"
            :title="String(cooldown.canceled)"
            @click="handleClick(cooldown)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .admin-panel-links-parent {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    pointer-events: auto;
    // border: 1px dashed yellow;
  }

  .has-scrollbars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20 20 0 20);
  }

  .has-scrollbars a {
    display: block;
  }

  .scrollbars-inner {
    position: relative;
    height: 100%;
    z-index: 1;
  }

  .admin-panel-links-parent,
  .admin-panel-links-parent * {
    pointer-events: auto;
  }

  .grid-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // border: 1px dashed red;
    z-index: 1;
  }
</style>
